import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rules belong to Sources; each Source may have many Rules. You'll manage Rules that belong to one Source, and may enable
and disable Rules as necessary.`}</p>
    <p>{`Changes to rules are instantaneous, though you may experience a short delay with any long-running Rules that may not
have finished executing prior to changing a Rule.`}</p>
    <h2 {...{
      "id": "create-a-rule",
      "style": {
        "position": "relative"
      }
    }}>{`Create a Rule`}<a parentName="h2" {...{
        "href": "#create-a-rule",
        "aria-label": "create a rule permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`You may create an unlimited number of Rules for any Source (keep in mind that `}<a parentName="p" {...{
        "href": "/docs/rules/overview#expedited-rule-performance"
      }}>{`long running Rules may negatively affect
web and other syncronous clients`}</a>{`).`}</p>
    <p><strong parentName="p">{`To create a new Rule:`}</strong></p>
    <ol>
      <li parentName="ol">{`Click on Rules in the navigation sidebar`}</li>
      <li parentName="ol">{`Click the "Add Rule" button`}</li>
      <li parentName="ol">{`Enter a Name and optional Description for the Rule`}</li>
      <li parentName="ol">{`Enter an Event that should trigger the rule, and optionally add additional `}<a parentName="li" {...{
          "href": "/docs/conditions/overview"
        }}>{`Conditions`}</a></li>
      <li parentName="ol">{`Add an `}<a parentName="li" {...{
          "href": "/docs/actions/overview"
        }}>{`Action`}</a>{` that should execute if the Conditions evaluate as true, and optionally add
additional `}<a parentName="li" {...{
          "href": "/docs/actions/overview"
        }}>{`Actions`}</a></li>
      <li parentName="ol">{`Click "Save Rule"`}</li>
    </ol>
    <h2 {...{
      "id": "update-a-rule",
      "style": {
        "position": "relative"
      }
    }}>{`Update a Rule`}<a parentName="h2" {...{
        "href": "#update-a-rule",
        "aria-label": "update a rule permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`You may update a Rule at any time. Keep in mind that changing a Rule will happen immediately, so the
`}<a parentName="p" {...{
        "href": "/docs/sources/managing-data-sources#monitor-source-events"
      }}>{`Event Debugger`}</a>{` may be useful in ensuring your change is as
you intended.`}</p>
    <p><strong parentName="p">{`To update an existing Rule`}</strong></p>
    <ol>
      <li parentName="ol">{`Click on Rules in the navigation sidebar`}</li>
      <li parentName="ol">{`Select the Rule you'd like to update`}</li>
      <li parentName="ol">{`Click on the Rule you'd like to update, and change its information as you wish`}</li>
      <li parentName="ol">{`Click "Save Rule"`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      